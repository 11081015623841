.slide-top {
  width: 100%;
  position: relative;
  overflow: hidden;
  top: 0;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  @include respond-to('phone') {
    background-position: bottom;
  }

  .container {
    flex: 1;

    @include respond-to('fullhd') {
      padding-bottom: 160px !important;
    }

    @include respond-to('tablet') {
      width: calc(100% - 100px);
    }

    @include respond-to('phone') {
      width: calc(100% - 60px);
    }
  }

  .swiper-container {
    .swiper-wrapper {
      &.disabled {
        transform: translate3d(0px, 0, 0) !important;
      }

      .swiper-slide {
        overflow: hidden;
        display: flex;
        height: unset;

        &__container {
          display: flex;
          height: auto;
          width: 100%;
        }
      }
    }

    .swiper-pagination {
      bottom: 24px;

      &.disabled {
        display: none;
      }

      .swiper-pagination-bullet {
        background: $primary-lighten-color;
        opacity: .5;
        width: 12px;
        height: 12px;
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: -1.5px;

    @include respond-to('phone') {
      font-size: 25px;
      line-height: 32px;
      letter-spacing: -1px;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.2px;
    margin-top: 50px;
    max-width: 470px;

    @include respond-to('phone') {
      font-size: 18px;
      line-height: 26px;
      margin-top: 20px;
    }
  }

  &__btn {
    margin-top: 50px;

    @include respond-to('phone') {
      margin-top: 15px;
    }
  }

  &__wrapper {
    display: grid;
    grid-gap: 5%;

    &--left {
      grid-template-areas: 'text image';
      grid-template-columns: 1.3fr 0.7fr;
    }

    &--right {
      grid-template-areas: 'image text';
      grid-template-columns: 0.7fr 1.3fr;
    }

    @include respond-to('phone') {
      grid-template-areas: 'text' 'image';
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }

    &__box {
      &--image {
        grid-area: image;
      }

      &--text {
        grid-area: text;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom;

    &--desktop {
      @include respond-to('phone') {
        display: none;
      }
    }

    &--mobile {
      display: none;
      position: inherit;

      @include respond-to('phone') {
        display: block;
      }
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 1s all;

    @include respond-to('phone') {
      display: none;
    }

    &--mobile {
      display: none;

      @include respond-to('phone') {
        display: block;
      }
    }
  }

  // do sprawdzenia
  .webgl {
    canvas {
      position: absolute;
      right: 0;
      top: 0;
      width: 100% !important;
      height: 100%;
      z-index: 1;
    }
  }
}


