/* media query break */

$break-tablet: 767px;
$break-desktop: 1024px;
$break-full-hd: 1440px;
$max-full: 1680px;

$font-Inter: 'Inter', sans-serif;

$white-color: #FFFFFF;
$black-color: #251825;
$black-darker-color: #121212;
$grey-color: #CCCCCC;
$grey-darker1-color: #617594;
$grey-darker2-color: #484851;
$grey-footer: #F8F9FC;
$grey-footer-text: #4F4F4F;

$primary-color: #00B082;
$primary-lighten-color: #DD1795;
//$primary-lighten2-color: #9AA3FE;
$primary-darker-color: linear-gradient(180deg, #251825 0%, #1D1C37 100%);;

$secondary-color: #DD1795;

$red-color: #ff0000;
