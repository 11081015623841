.two-col-wysiwyg {
  .container {
    &-left {
      padding-left: 150px;
      padding-right: 70px;

      @include respond-to('fullhd') {
        padding-left: 160px;
        padding-right: 105px;
      }
      @include respond-to('tablet') {
        width: 100%;
        padding-left: 50px;
        padding-right: 25px;
      }
      @include respond-to('phone') {
        width: 100vw;
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    &-right {
      padding-left: 70px;
      padding-right: 150px;

      @include respond-to('fullhd') {
        padding-left: 105px;
        padding-right: 160px;
      }
      @include respond-to('tablet') {
        width: 100%;
        padding-left: 25px;
        padding-right: 50px;
      }
      @include respond-to('phone') {
        width: 100vw;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include respond-to('phone') {
      grid-template-columns: 1fr;
    }

    .rich-text {
      h2 {
        font-size: 56px;
        line-height: 60px;
      }

      h3 {
        font-weight: 800;
      }
    }
  }
}
