.details__body {
  &__content {
    max-width: 940px;
    margin: 0 auto;

    h2 {
      font-weight: 800;
      font-size: 50px;
      line-height: 60px;
      letter-spacing: -0.8px;
      margin-bottom: 30px;

      @include respond-to('phone') {
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -1px;
      }
    }

    h3 {
      font-size: 28px;
      font-weight: 800;
      line-height: 35px;
      letter-spacing: -0.5px;
      margin-bottom: 30px;

      @include respond-to('phone') {
        font-size: 24px;
        line-height: 30px;
      }
    }

    h4 {
      font-weight: 800;
      font-size: 28px;
      line-height: 35px;
      letter-spacing: -0.5px;
      margin-bottom: 30px;

      @include respond-to('phone') {
        font-size: 18px;
        line-height: 25px;
      }
    }

    a, p {
      position: relative;
      font-weight: 400;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 0.5px;
      margin: 30px auto;

      &:last-child {
        margin-bottom: 0;
      }

      @include respond-to('phone') {
        font-size: 18px;
        line-height: 28px;
      }
    }

    i {
      font-style: italic;
      font-size: 24px;
      line-height: 38px;
      background: $primary-lighten-color;
      color: $black-color !important;

      @include respond-to('phone') {
        font-size: 18px;
        line-height: 28px;
      }
    }

    b {
      font-weight: 800;
    }

    a {
      color: $primary-lighten-color;
      transition: .25s ease;

      &:hover {
        color: lighten($primary-lighten-color, 15%);
        transition: .25s ease;
      }
    }

    ul {
      list-style: disc;
    }

    ol {
      list-style: decimal;
    }

    ul, ol {
      position: relative;
      margin-left: 20px;
      margin-top: -40px;

      li {
        font-weight: 500;
        font-size: 18px;
        line-height: 38px;
        letter-spacing: 0.5px;
      }
    }

    img {
      display: block;
      border-radius: 3px;
      transition: .25s ease;

      &.left {
        margin-right: auto;
      }

      &.right {
        margin-left: auto;
      }

      &.full-width {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 300px);
        height: auto;
        object-fit: contain;

        @include respond-to('fullhd') {
          width: calc(100vw - 320px);
        }

        @include respond-to('tablet') {
          width: calc(100vw - 100px);
        }

        @include respond-to('phone') {
          position: static;
          left: 0;
          transform: translateX(0);
          width: 100%;
        }
      }

      @include respond-to('phone') {
        width: 100%;
        height: 100%;
      }
    }

    @include respond-to('phone') {
      h2, h3, h4, p {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .rich-text > *:first-child {
      margin-top: 0;
    }
  }
}